module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/vercel/path0/packages/recipes/src/layouts/recipe.js","default":"/vercel/path0/packages/recipes/src/pages/index.js"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Erica's Recipes","short_name":"EAK Recipes","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/emoji-chef.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fc916d93b565d4609e3dedf7fe712158"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
